<template>
  <section class="container my-40">
    <v-sheet
    rounded
    elevation="4"
    class="p-24"
    >
      <div class="row">
        <div class="col-3">
          <v-field>
            <v-input
                v-model.number="tabsLength"
                type="number"
            />
          </v-field>
        </div>
      </div>

      <div class="row mt-40">
        <div class="col">
          <v-tabs
              outlined
          >
            <v-tab
                v-for="tab in tabs"
                :key="tab.id"
                :label="tab.title"
            >
              <div class="row">
                <div
                    class="col p-24"
                    v-html="tab.text"
                />
              </div>
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </v-sheet>

    <div class="row mt-40">
      <div class="col">
        <v-tabs>
          <v-tab
              v-for="tab in tabs"
              :key="tab.id"
              :label="tab.title"
          >
            <div class="row">
              <div
                  class="col p-24"
                  v-html="tab.text"
              />
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import { VTabs, VTab } from '@components/base'
import { createCustomArray } from '@utils/helpers'
import VField from '@components/base/VField/VField'
import VInput from '@components/base/VField/VInput/VInput'
import VSheet from '@components/base/VSheet/VSheet'

export default {
  name: 'STabs',

  components: { VSheet, VInput, VField, VTab, VTabs },

  data () {
    return {
      tabsLength: 2
    }
  },

  computed: {
    tabs () {
      return createCustomArray({
        length: this.tabsLength,
        template: i => ({
          id: i,
          title: `Вкладка ${i}`,
          text: `<h2 class="mb-24">Текст для вкладки ${i}</h2> Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab adipisci asperiores aut, beatae deserunt dolorem doloremque dolores ea, eaque laboriosam neque sunt velit! Asperiores cumque deserunt eos minima nostrum.`
        })
      })
    }
  }
}
</script>
